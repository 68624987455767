<template>
  <div>
    <v-overlay :value="overlay"><v-progress-circular :size="70" :width="7" color="green"
        indeterminate></v-progress-circular></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-0">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Purchase summary Report - Buying tracking report
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                  </div>
                </div>
              </div>

              <v-card-text>
                <v-card-text class="px-0 py-0">
                  <v-form ref="frm_filter">
                    <v-row>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                            </strong></span>Auction center</label>

                        <v-autocomplete :items="auctionCenter" item-text="auction_center_name" item-value="id"
                          hide-details="auto" v-model="editedItem.auctionCenter" @change="fillData" class="
                                                            input-style
                                                            font-size-input
                                                            text-light-input
                                                            placeholder-light
                                                            border-radius-md
                                                            select-style
                                                            mt-3
                                                            mb-0
                                                          " outlined height="46" single-line
                          :rules="[(v) => !!v || 'Auction center is required']" placeholder="Select auction center">
                          <template v-slot:selection="{ item }">
                            <v-text label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-caption ls-0">{{
                                item.auction_center_name
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                            </strong></span>Season</label>

                        <v-autocomplete :items="season" item-value="id" hide-details="auto" v-model="editedItem.season"
                          class="
                                                            input-style
                                                            font-size-input
                                                            text-light-input
                                                            placeholder-light
                                                            border-radius-md
                                                            select-style
                                                            mt-3
                                                            mb-0
                                                          " outlined height="46" @change="filterSeasonSale()" single-line
                          :rules="[(v) => !!v || 'Season is required']" placeholder="Select Season">
                          <template v-slot:selection="{ item }">
                            <v-text name="ss_name" label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-caption ls-0">{{
                                item.name
                              }}</span>
                            </v-text>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-text name="ss_name" label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-caption ls-0">{{
                                item.name
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                            </strong></span>Sale No</label>

                        <v-autocomplete :items="seasonSale" item-value="id" hide-details="auto"
                          v-model="editedItem.seasonSale" class="
                                                            input-style
                                                            font-size-input
                                                            text-light-input
                                                            placeholder-light
                                                            border-radius-md
                                                            select-style
                                                            mt-3
                                                            mb-0
                                                          " outlined height="46" single-line
                          :rules="[(v) => !!v || 'Sale No is required']" placeholder="Select Sale No">
                          <template v-slot:selection="{ item }">
                            <v-text name="ss_name" label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-caption ls-0">{{
                                item.season_sale
                              }}</span>
                            </v-text>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-text name="ss_name" label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-caption ls-0">{{
                                item.season_sale
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                            </strong></span>Tea
                          type</label>
                        <v-autocomplete :items="teaType" item-value="id" item-text="tea_type_name"
                          v-model="editedItem.teaType" class="
                                                            input-style
                                                            font-size-input
                                                            text-light-input
                                                            placeholder-light
                                                            select-style
                                                            mt-3
                                                          " outlined chips multiple height="46" single-line
                          placeholder="Select Tea type" :rules="[(v) => !!v || 'Tea type is required']">
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-white text-caption ls-0">{{
                                item.tea_type_name
                              }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text text-caption">
                              (+{{ editedItem.teaType.length - 1 }}
                              others)
                            </span>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1">Item Name</label>

                        <v-autocomplete :items="item_names" item-value="item_name" item-text="item_name"
                          v-model="editedItem.item_name" class="
                                                            input-style
                                                            font-size-input
                                                            text-light-input
                                                            placeholder-light
                                                            select-style
                                                            mt-3
                                                          " outlined chips multiple height="46" single-line
                          placeholder="Select Item Name">
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-white text-caption ls-0">{{
                                item.item_name
                              }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text text-caption">
                              (+{{ editedItem.item_name.length - 1 }}
                              others)
                            </span>
                          </template>
                          <template v-slot:prepend-item>
                            <v-list-item ripple @click="toggle">
                              <v-list-item-action>
                                <v-icon :color="
                                  editedItem.item_name.length > 0
                                    ? 'indigo darken-4'
                                    : ''
                                ">
                                  {{ icon }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions class="card-padding d-flex justify-end">
                  <v-btn @click="cancel" elevation="0" :ripple="false" height="46" class="
                                                      font-weight-bold
                                                      text-capitalize
                                                      btn-ls btn-secondary
                                                      bg-light
                                                      py-3
                                                      px-6
                                                    ">Cancel</v-btn>

                  <v-btn @click="search" elevation="0" :ripple="false" height="46" dark class="
                                                      font-weight-bold
                                                      text-capitalize
                                                      btn-ls btn-primary
                                                      bg-success
                                                      py-3
                                                      px-6
                                                    ">Search</v-btn>

                  <v-btn v-if="showTable" @click="excelExport" elevation="0" :ripple="false" height="46" dark class="
                                                    font-weight-bold
                                                    text-capitalize
                                                    btn-primary
                                                    bg-success
                                                    py-5
                                                    px-5
                                                    shadow
                                                  ">
                    <v-icon size="20" class="text-white mr-1">
                      fas fa-cloud-download-alt
                    </v-icon>
                    Download
                  </v-btn>

                </v-card-actions>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-card class="card-shadow border-radius-xl mt-5" v-show="showTable">
        <div class="card-header-padding">
          <div class="d-flex align-center">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Total sold
              </h5>
            </div>
          </div>
        </div>
        <v-card-text class="card-padding">
          <v-data-table :headers="headers_total_sold" :items="result.total_sold ? result.total_sold : []" fixed-header
            hide-default-footer class="table" disable-pagination>
            <!-- <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Purchase history</v-toolbar-title>
              </v-toolbar>
            </template> -->
            <template slot="body.append">
              <tr class="bolder">
                <th class="title font-weight-bolder">Grand Total</th>

                <th class="title font-weight-bolder">
                  {{ sumField(result.total_sold, "total_sold") }}
                </th>
                <th class="title font-weight-bolder">
                  {{ sumField(result.total_sold, "total_value") }}
                </th>
                <th class="title font-weight-bolder">
                  {{
                    parseFloat(
                      sumField(result.total_sold, "total_value") /
                      sumField(result.total_sold, "total_sold")
                    ).toFixed(3)
                  }}
                </th>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <v-card class="card-shadow border-radius-xl mt-5" v-show="showTable">
        <div class="card-header-padding">
          <div class="d-flex align-center">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                East Tea Buying
              </h5>
            </div>
          </div>
        </div>
        <v-card-text class="card-padding">
          <v-data-table :headers="headers_eastea_buying" :items="result.eastea_buying ? result.eastea_buying : []"
            fixed-header hide-default-footer disable-pagination class="table">
            <!-- <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Purchase history</v-toolbar-title>
              </v-toolbar>
            </template> -->
            <template slot="body.append">
              <tr class="bolder">
                <th class="title font-weight-bolder">Grand Total</th>
                <th></th>
                <th class="title font-weight-bolder">
                  {{ sumField(result.total_eastea_buying, "total_sold") }}
                </th>
                <th class="title font-weight-bolder">
                  {{ sumField(result.total_eastea_buying, "total_value") }}
                </th>
                <th class="title font-weight-bolder">
                  {{
                    parseFloat(
                      sumField(result.total_eastea_buying, "total_value") /
                      sumField(result.total_eastea_buying, "total_sold")
                    ).toFixed(3)
                  }}
                </th>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { json2excel } from "js2excel";
import api from "../api";
import masterApi from "../../../master/master-get-api";
import formatDate from "../../../../global-data/dateFormatter";
export default {
  data() {
    return {
      showTable: false,
      overlay: false,
      item_names: [],
      auctionCenter: [],
      seasonSale: [],
      teaType: [],
      season: [],
      toatl_eastea_buying: 0,
      result: {
        total_sold: [],
        eastea_buying: [],
        total_eastea_buying: [],
      },

      editedItem: {
        auctionCenter: 0,
        seasonSale: 0,
        teaType: null,
        item_name: [],

        season: 0, // not used in API- just to filter Sale No
      },

      headers_total_sold: [
        {
          text: "Item",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: false,
        },
        {
          text: "total sold",
          value: "total_sold",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: false,
        },
        {
          text: "total value",
          value: "total_value",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: false,
        },
        {
          text: "average price",
          value: "average_price",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: false,
        },
      ],

      headers_eastea_buying: [
        {
          text: "Buyer Location",
          value: "buyer_location",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: false,
        },
        {
          text: "Item",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: false,
        },
        {
          text: "Total Buying",
          value: "total_sold",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: false,
        },
        {
          text: "total value",
          value: "total_value",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: false,
        },
        {
          text: "average price",
          value: "average_price",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: false,
        },
      ],
      // Pagination
      options: {},
      numberOfPages: 100,
      total_rows: 10,
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.editedItem.item_name = [];
        } else {
          this.editedItem.item_name = this.item_names.slice();
        }
      });
    },
    async initialize() {
      this.overlay = true;

      try {
        this.item_names = await api.getItemNames();
        this.auctionCenter = await masterApi.getAuctionCenter();

        this.teaType = await masterApi.getTeaType();
        this.season = await masterApi.getSeason();
      } catch (err) {
        this.showErrorAlert(err);
      }
      this.overlay = false;
    },

    sumField(array, key) {
      // sum data in give key (property)
      return array.reduce((a, b) => parseInt(a) + parseInt(b[key] || 0), 0);
    },
    fillData() {
      this.editedItem.season = 0;
      this.editedItem.seasonSale = 0;
      this.editedItem.teaType = 0;
      this.editedItem.item_name = [];
      this.seasonSale = [];
    },
    async filterSeasonSale() {
      this.seasonSale = [];
      this.overlay = true;
      this.editedItem.seasonSale = 0;
      this.seasonSale = await api.getSeasonSaleBySeasonAndAuctionCenter(
        this.editedItem.season,
        this.editedItem.auctionCenter
      );
      this.overlay = false;
    },

    cancel() {
      this.item_names = [];
      this.showTable = false;
    },

    frmvalidate() {
      return this.$refs.frm_filter.validate();
    },
    async search() {
      try {
        let req = await this.getReq();
        let r = await api.getReport(req);
        this.showTable = true;
        this.result.eastea_buying = r.eastea_buying;
        this.result.total_sold = r.total_sold;

        console.log("TOTAL SOLD", this.result.total_sold);
        console.log("Eastea SOLD", this.result.eastea_buying);
        this.result.total_eastea_buying = r.eastea_buying;
        this.overlay = false;
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    async getReq() {
      if (this.frmvalidate()) {
        this.overlay = true;
        let item_names = [];
        // Checking Item name -SELECT ALL-
        if (this.editedItem.item_name.length == this.item_names.length) {
          this.editedItem.item_name.forEach((el) => {
            item_names.push(el.item_name);
          });
        }
        // SELECT SOME
        if (
          this.editedItem.item_name.length > 0 &&
          this.editedItem.item_name.length != this.item_names.length
        ) {
          item_names = this.editedItem.item_name;
        }

        let req = {
          season_sale: this.editedItem.seasonSale,
          tea_type_id: this.editedItem.teaType,

          item_name: item_names,
        };
        return req;
      }
    },
    async excelExport() {
      try {
        this.overlay = true;
        let req = await this.getReq();
        await api.purchaseSummeryExcelImport(req);

        this.overlay = false;
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",
        timer: 3000,
        icon: "error",
        showConfirmButton: false,
      });
    },
    showNoDataAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,

        timer: 3000,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    dateFormater(date) {
      return formatDate.formatDate(date);
    },
  },
  computed: {
    selectAll() {
      return this.editedItem.item_name.length === this.item_names.length;
    },
    selectSome() {
      return this.editedItem.item_name.length > 0 && !this.selectAll;
    },
    icon() {
      if (this.selectAll) return "ni-fat-remove text-lg";
      if (this.selectSome) return "ni-fat-delete";
      return "fa-square-o";
    },
    selectAllMark() {
      return this.mark.length === this.marks.length;
    },
    selectSomeMark() {
      return this.mark.length > 0 && !this.selectAllMark;
    },

    icon1() {
      if (this.selectAllMark) return "ni-fat-remove text-lg";
      if (this.selectSomeMark) return "ni-fat-delete";
      return "fa-square-o";
    },

    selectAllCompanyWareHouse() {
      return this.company_warehouse.length === this.company_warehouses.length;
    },
    selectSomeCompanyWareHouse() {
      return (
        this.company_warehouse.length > 0 && !this.selectAllCompanyWareHouse
      );
    },

    icon2() {
      if (this.selectAllCompanyWareHouse) return "ni-fat-remove text-lg";
      if (this.selectSomeCompanyWareHouse) return "ni-fat-delete";
      return "fa-square-o";
    },

    selectAllGrade() {
      return this.grade.length === this.grades.length;
    },
    selectSomeGrade() {
      return this.grade.length > 0 && !this.selectAllGrade;
    },

    icon3() {
      if (this.selectAllGrade) return "ni-fat-remove text-lg";
      if (this.selectSomeGrade) return "ni-fat-delete";
      return "fa-square-o";
    },
  },
  watch: {
    options: {
      handler() {
        this.search();
      },
    },
    deep: true,
  },
};
</script>

<style></style>
