import axios from "axios";

export default {
  async getItemNames() {
    return await axios
      .get(`catalogue-upload/item-name`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getSeasonSaleBySeasonAndAuctionCenter(season_id, auction_center_id) {
    return await axios
      .get(
        `season-sale/sale-by/season/auction_center?season_id=${season_id}&auction_center_id=${auction_center_id}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getReport(req) {
    return await axios
      .get(`report/buying_tracking`, { params: req })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async purchaseSummeryExcelImport(req) {
    let date_time = new Date();
    date_time = `${(date_time.getMonth() + 1).toString().padStart(2, "0")}/${date_time.getDate().toString().padStart(2, "0")}/${date_time
      .getFullYear()
      .toString()}`;
    let fileName = `Purchase Summary-` + date_time.replaceAll(`/`, `-`);

    axios({
      method: "get",
      url: `report/download-summery`,
      params: req,
      responseType: "blob",
    })
      .then((res) => {
        console.log("RES", res);
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.ms-excel",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err.response.data.message);
      });
  },
};
